import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const aboutBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const H3HeadingBps = css(
  mq({
    width: [328, '100%', 416, 550],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: ['bold', 'bold', 'bold', 'normal'],
    marginBottom: [16]
  })
)

export const H3ContentBps = css(
  mq({
    width: [280, '100%', 417, 555],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: ['bold', 'bold', 'bold', 'normal'],
    marginBottom: [16]
  })
)

export const H4Bps = css(
  mq({
    width: [290, 420, 416, 507]
  })
)

export const PBps = css(
  mq({
    width: [290, '100%', 420, 420],
    marginTop: [30, 16, 16, 16],
    marginBottom: [30]
  })
)

export const ImgHeadingBps = css(
  mq({
    width: [290, 350, 400, 450],
    height: [290, 350, 400, 450],
    marginLeft: ['none', 'none', 'auto', 'auto']
  })
)

export const PHeadingBps = css(
  mq({
    maxWidth: [328, '100%', 416, 416],
    fontSize: [14],
    marginBottom: [16]
  })
)

export const PContentBps = css(
  mq({
    width: [290, '100%', 422, 395]
  })
)

export const sizeBox = css(
  mq({
    maxWidth: ['100%', '100%', 540, 540],
    marginLeft: ['auto']
  })
)

export const styledAbout = css`
  ${aboutBps};
`
export const header = css`
  display: flex;
  img {
    ${ImgHeadingBps};
  }
  h3 {
    ${H3HeadingBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.71;
    ${PHeadingBps};
  } 
`

export const blocContent = css`
  background: #f3f5f5;
`

export const stylePress = css`
  background-color: #9013fe;
`

export const contentPress = css`
  display: flex;
  span {
    font-size: 14px;
    line-height: 1.71;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 16px;
  }
  h3 {
    ${H3ContentBps};
    display: grid;
  }
  p {
    ${PContentBps};
    color: #fff;
  }
`

export const styledBox = css`
  span {
    font-size: 14px;
    line-height: 1.71;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
  }
  h4 {
    display: grid;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PBps};
  }
`